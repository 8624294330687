@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
//media query
$cel: 479px;
$nocel: 479px;
$tableta: 768px;
$tableta-land: 1024px;
$hd: 1600px;

@mixin cel {
  @media (max-width: #{$cel}) {
    @content;
  }
}

@mixin nocel {
  @media (min-width: #{$nocel}) {
    @content;
  }
}

@mixin tableta_land {
  @media (min-width: #{$tableta-land}) and (max-width: #{$hd - 1px}) {
    @content;
  }
}

@mixin tableta {
  @media (min-width: #{$tableta - 1px}) and (max-width: #{$tableta-land - 1px}) {
    @content;
  }
}

@mixin hd {
  @media (min-width: #{$hd}) {
    @content;
  }
}

$cafe: #bda47b;
$azul: #2e4565;
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Prata", serif;
  color: $azul;
}
h3 {
  color: $cafe !important;
}
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body,
html {
  height: 100%;
  line-height: 1.8;
  font-family: "Roboto", sans-serif;
  color: #1f1f1f;
  font-size: 20px;
}

/* Create a Parallax Effect */
.bgimg-1,
.bgimg-2,
.bgimg-3 {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* First image (Logo. Full height) */
.bgimg-1 {
  background-image: url("/imas/cover_bodas_ixtapa_zihuatanejo.jpg");
  min-height: 100%;
}

/* Second image (Portfolio) */
.bgimg-2 {
  background-image: url("/imas/beachPlayaBodaWedding.jpg");
  min-height: 400px;
}

/* Third image (Contact) */
.bgimg-3 {
  background-image: url("/imas/weddingsMexicoIxtapa.jpg");
  min-height: 400px;
}

.w3-wide {
  letter-spacing: 10px;
}
.w3-hover-opacity {
  cursor: pointer;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1600px) {
  .bgimg-1,
  .bgimg-2,
  .bgimg-3 {
    background-attachment: scroll;
    min-height: 400px;
  }
}

img {
  width: 100%;
  height: auto;
}
#myNavbar {
  display: flex;
  align-items: center;
  a {
    justify-content: flex-end;
  }
  .logoMenu {
    padding: 0.7em;
    img {
      width: 60px;
    }
  }
  .ligasMenu {
    flex: 1;
    justify-content: flex-end;
    i {
      font-size: 1.5em;
      color: $cafe;
    }
  }
}
#estadisticas {
  background-color: $azul;
  color: $cafe;
  span {
    font-family: "Prata", serif;
  }
}
#sublogo {
  max-width: 240px;
  height: auto;
}

#servicios {
  background-color: rgb(255, 251, 220);
  .servicio {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
  }
  img {
    flex: 1 1 50%;
  }
  .bandeja {
    display: inherit;
    flex: 1 1 50%;
    @include cel {
      flex-basis: 100%;
    }
    padding: 2em;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    h2 {
      margin: 0em;
    }
    p {
      margin: 0.2em 0;
    }
  }

  .servicio:nth-child(2n) {
    @include cel {
      .bandeja:first-child {
        order: initial;
      }
    }
    .bandeja:first-child {
      order: 1;
      @include cel {
        order: initial;
      }
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  width: 100%;
  height: 0;
  background-color: khaki;
}
.videoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
footer {
  background-color: $cafe;
  i {
    color: #fff;
  }
  img {
    width: 100px;
    height: auto;
  }
}
.glide__slide {
  background-color: $azul;
  color: #bda47b;
}
#play {
  z-index: 200;
  width: 70px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  position: absolute;
}
.wa {
  width: 50px;
  position: fixed;
  z-index: 600;
  right: 20px;
  bottom: 20px;
}
#datosConta {
  i {
    color: $cafe;
  }
}
#paquete {
  background-color: $azul;
  color: #fff;
  h4 {
    color: #fff;
  }
  i {
    font-size: 2em;
    margin-right: 0.5em;
  }
  a {
    vertical-align: middle;
  }
}
.btn {
  background-color: $cafe;
  color: #fff;
  i {
    color: #fff !important;
  }
  b {
    color: #fff;
  }
  &:hover {
    background-color: #fff !important;
    color: $cafe !important;
    border: solid $cafe 1px;
    i {
      color: $cafe;
    }
    b {
      color: $cafe;
    }
  }
}
b {
  color: $cafe;
}

.desactivado {
  opacity: 0.2;
  pointer-events: none;
}

.glide{

  border-bottom: solid 2px $azul;
}
.glide__track {
  .glide__slide {
    padding: 1em;
    color: #fff;
    footer{
    background-color: $azul;
    }
  }
}
